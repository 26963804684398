import { datadogRum } from "@datadog/browser-rum";
import { isPlatform } from "@ionic/core";
// eslint-disable-next-line no-restricted-imports
import { segmentPluginHelper } from "@src/lib/analytics/src/lib/segmentPluginHelper";

export interface LogErrorProps {
  // try catch errors are `unknown` type.
  error: unknown;
  metadata?: Record<string, unknown>;
}

export type EventLogger = (eventKey: string, properties?: Record<string, any>) => void;
export type ErrorLogger = (eventName: string, params: LogErrorProps) => void;

const trackSegmentEvent = ({
  eventKey,
  properties,
}: {
  eventKey: string;
  properties?: Record<string, any>;
}) => {
  if (isPlatform("capacitor")) {
    void segmentPluginHelper.capacitorTrack(eventKey, properties);
  } else {
    segmentPluginHelper.webTrack(eventKey, properties);
  }
};

export function logEvent(eventKey: string, properties?: Record<string, any>): void {
  trackSegmentEvent({ eventKey, properties });
  datadogRum.addAction(eventKey, { ...properties });
}

export function logError(eventName: string, params: LogErrorProps): void {
  const { error: unknownError, metadata } = params;
  const isError = unknownError instanceof Error;
  const error = isError ? unknownError : String(unknownError);
  datadogRum.addError(error, {
    eventName,
    ...metadata,
  });
}

interface DeprecatedDoNotUseLogErrorProps {
  message: string;
  metadata?: Record<string, unknown>;
}

/**
 * @deprecated
 * Use logError from @src/appV2/lib/analytics
 * FIXME: Migrate all usages of deprecatedDoNotUseLogError in src/app to directly use logError
 */
export function deprecatedDoNotUseLogError(params: DeprecatedDoNotUseLogErrorProps): void {
  const { message, metadata } = params;
  logError("Legacy Error Logs from src/app", { error: message, metadata });
}
