import { isDefined } from "@clipboard-health/util-ts";
import { useEffect, useRef } from "react";

import { logEvent } from "./log";

/**
 * Log an event for the event name. We only log once, regardless of subsequent payload changes.
 * This ensures that we don't repeatedly log due to shallow changes to the payload object,
 * even if the payload contents are stable.
 * We may adjust this in the future, by comparing the payload values against prior payload values.
 */
export function useLogEffect(eventName: string | undefined, payload?: Record<string, any>) {
  /**
   * Using `useRef`, we ensure that changes to the payload object do not retrigger an
   * effect. We do this intentionally since our usage is to log once, on the first instantiation
   * of this hook.
   */
  const payloadRef = useRef(payload);

  useEffect(() => {
    if (isDefined(eventName)) {
      logEvent(eventName, payloadRef.current);
    }
  }, [eventName]);
}
